import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {PeriodPayModel} from '../../models/company/periodPay.model';

@Injectable()
export class PeriodPayService {

  url: string;

  // Observables
  private tablePeriodsSubject = new Subject<PeriodPayModel>();

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api;
  }

  addPeriodPay(period: any) {
    return this.http.post(`${this.url}/admin/empresas/${localStorage.getItem('company')}/periodos_pago/`, period)
      .pipe(
        map(
          per => per
        )
      );
  }

  getPeriodsPay(): Observable<Array<PeriodPayModel>> {
    return this.http.get(`${this.url}/admin/empresas/${localStorage.getItem('company')}/periodos_pago/`)
      .pipe(
        map(
          per => per as Array<PeriodPayModel>
        )
      );
  }

  updatePeriodPay(period: PeriodPayModel, id: number): Observable<PeriodPayModel> {
    return this.http.put(`${this.url}/admin/empresas/${localStorage.getItem('company')}/periodos_pago/${id}/`, period)
      .pipe(
        map(
          per => per as PeriodPayModel
        )
      );
  }
  // Methods of Observable Branch

  setUpdateTablePeriod(branch: PeriodPayModel) {
    this.tablePeriodsSubject.next(branch);
  }

  getStatusTablePeriod() {
    return this.tablePeriodsSubject.asObservable();
  }
}
