import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable, Subject, pipe} from 'rxjs';
import { PagerModel } from '../../models/update-law/updatelaw.model';


@Injectable({
  providedIn: 'root'
})
export class UpdateLawService {
  url: string;
  private LawsUpdateSubject = new Subject<PagerModel>();
  constructor(
    private http: HttpClient,
  ) { 
    this.url = environment.api;
  }

  addLawUpdate(lawUpdate){
    return this.http.post(`${this.url}/admin/actualizaciones_ley/`,lawUpdate)
    .pipe(
      map(
        lawUpdate => lawUpdate
      )
    );
  }

  getLawUpdate(){
    return this.http.get(`${this.url}/admin/actualizaciones_ley/`)
    .pipe(
      map(
        lawsUpdate => lawsUpdate
      )
    );
  }

  getLawUpdateById(id:string){
    return this.http.get(`${this.url}/admin/actualizaciones_ley/${id}`)
    .pipe(
      map(
        lawsUpdateById => lawsUpdateById
      )
    );
  }

  setUpdateViewLaws(law: PagerModel) {
    this.LawsUpdateSubject.next(law);
  }

  getStatusViewLaws() {
    return this.LawsUpdateSubject.asObservable();
  }
}
