import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {JobModel} from '../../models/company/job.model';
import {JobResponseModel} from '../../models/company/jobResponse.model'
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable()
export class JobService {

  url: string;

  // Observables
  private tableJobSubject = new Subject<JobModel>();

  private editJobSubject = new Subject<JobModel>();

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api;
  }

  addJob(job: JobModel, dep: string): Observable<JobModel> {
    return this.http.post(`${this.url}/admin/departamentos/${dep}/puestos/`, job)
      .pipe(
        map(
          jobR => jobR as JobModel
        )
      );
  }

  updateJob(job: JobModel, dep: string): Observable<JobModel> {
    return this.http.post(`${this.url}/admin/departamentos/${dep}/puestos/${job.id}`, job)
      .pipe(
        map(
          jobR => jobR as JobModel
        )
      );
  }

  getJobs(): Observable<Array<JobModel>> {
    return this.http.get(`${this.url}/admin/empresas/${localStorage.getItem('company')}/puestos/`)
      .pipe(
        map(
          jobR => jobR as Array<JobModel>
        )
      );
  }

  getJobsByCompany(id_deparment): Observable<Array<JobResponseModel>> {
    return this.http.get(`${this.url}/admin/rh/empresa/${localStorage.getItem('company')}/departamentos/${id_deparment}/puestos/`)
      .pipe(
        map(
          jobR => jobR as Array<JobResponseModel>
        )
      );
  }

  // Methods of Observable JOb

  setUpdateTableJob(job: JobModel) {
    this.tableJobSubject.next(job);
  }

  getStatusTableJob() {
    return this.tableJobSubject.asObservable();
  }

  setToEditJob(job: JobModel) {
    this.editJobSubject.next(job);
  }

  getToEditJob() {
    return this.editJobSubject.asObservable();
  }
}
