import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateMomentService {

  constructor() {
    moment().locale('es');
  }

  dateWithoutHours(date) {
    return  moment(date).format('DD-MM-YYYY');
  }

  dateIncapacity(date) {
    return  moment(date).format('MM-DD-YYYY');
  }

  yearMountDay(date) {
    return  moment(date, 'YYYY-MM-DD').format().toString();
  }

  dateformartter(date: string) {
    const [day, month, year] = date.split('-');
    return moment([year, (Number(month) - 1), day, 0, 0, 0, 0]).format('YYYY-MM-DD');
  }

  dateCalendar(date){
    return moment(date).endOf('day').toISOString()
  }
}
