import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable, Subject, pipe} from 'rxjs';
import { FixedConceptCollaborate } from '../../models/collaborator/fixed-concepts.model';

@Injectable({
  providedIn: 'root'
})
export class FixedConceptsServiceCollaborate {
  typesBenefit:any[] = [
    {
      beneficio:['PENSION_ALIMENTICIA_NETA', 'BONO', 'CAJA_AHORRO'],
      name: "Porcentaje",
      value: "PORCENTAJE"
    },{
      beneficio:['BONO', 'CAJA_AHORRO'],
      name: "Monetario",
      value: "MONETARIO"
    },{
      beneficio:['BONO', 'CAJA_AHORRO'],
      name: "Veces Salario Mínimo",
      value: "VECES"
    }
  ];
  url:string;
  constructor(private http: HttpClient){
    this.url = environment.api
  }

  getBaseBenefit(benefit){
    const arrayBase:any=[];
    this.typesBenefit.forEach((base)=>{
      base.beneficio.forEach(baseBenefit  => {
        if(baseBenefit == benefit ){
          arrayBase.push(base)
        }
      });
    });
    return arrayBase;
  }

  applyconcept(concept: FixedConceptCollaborate, company){
    console.log(concept)
    return this.http.post(`${this.url}/admin/empresas/${company}/conceptos_fijos_empresa/`, concept)
    .pipe(
      map(
        fixed => fixed
      )
    )
  }
}
