import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ImssModel} from '../../models/tools/imss.model';
import {UmaModel, UmaResponse} from '../../models/tools/uma.model';
import {SdiModel, SdiResponse} from '../../models/tools/sdi.model';
import {FiniquitoModel, FiniquitoResponse} from '../../models/tools/finiquito.model';
import {DominicalModel, DominicalResponse} from '../../models/tools/dominical.model';
import {VacationModel, VacationResponse} from '../../models/tools/vacation.model';
import {BonusModel, BonusResponse} from '../../models/tools/bonus.model';
import {SalaryModel, SalaryResponse} from '../../models/tools/salary.model';
import {HoursModel, HoursResponse} from '../../models/tools/hours.model';
import {CompareSalaryModel, CompareSalaryResponse} from '../../models/tools/compare-salary.model';

@Injectable()
export class ToolsService {

  url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api;
  }

  getCalculateImss(data: any): Observable<ImssModel> {
    return this.http.post(`${this.url}/admin/herramientas/calculadora_imss/`, data)
      .pipe(
        map(res => res as ImssModel)
      );
  }

  getCalculateUMA(data: UmaModel): Observable<UmaResponse> {
    return this.http.post(`${this.url}/admin/herramientas/veces_uma/`, data)
      .pipe(
        map(res => res as UmaResponse)
      );
  }

  getCalculateSDI(data: SdiModel): Observable<SdiResponse> {
    return this.http.post(`${this.url}/admin/herramientas/salario_diario_integrado/`, data)
      .pipe(
        map(res => res as SdiResponse)
      );
  }

  getCalculateFiniquito(data: FiniquitoModel): Observable<FiniquitoResponse> {
    return this.http.post(`${this.url}/admin/herramientas/finiquito/`, data)
      .pipe(
        map(res => res as FiniquitoResponse)
      );
  }

  getCalculateDominical(data: DominicalModel): Observable<DominicalResponse> {
    return this.http.post(`${this.url}/admin/herramientas/prima_dominical/`, data)
      .pipe(
        map(res => res as DominicalResponse)
      );
  }

  getCalculateVacation(data: VacationModel): Observable<VacationResponse> {
    return this.http.post(`${this.url}/admin/herramientas/vacaciones/`, data)
      .pipe(
        map(res => res as VacationResponse)
      );
  }

  getCalculateBonus(data: BonusModel): Observable<BonusResponse> {
    return this.http.post(`${this.url}/admin/herramientas/aguinaldo/`, data)
      .pipe(
        map(res => res as BonusResponse)
      );
  }
  getCalculateSalary(data: SalaryModel): Observable<SalaryResponse> {
    return this.http.post(`${this.url}/admin/herramientas/veces_salario_minimo/`, data)
      .pipe(
        map(res => res as SalaryResponse)
      );
  }

  getCalculateHours(data: HoursModel): Observable<HoursResponse> {
    return this.http.post(`${this.url}/admin/herramientas/horas_extra/`, data)
      .pipe(
        map(res => res as HoursResponse)
      );
  }

  getCalculateCompareSalary(data: CompareSalaryModel): Observable<CompareSalaryResponse> {
    return this.http.post(`${this.url}/admin/herramientas/comparador_salarios/`, data)
      .pipe(
        map(res => res as CompareSalaryResponse)
      );
  }

}
