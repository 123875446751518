import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettlementService {
  url:string;
  constructor(
    private http: HttpClient
  ) {
       this.url = environment.api
  }

  // Liquidación
  CalcularFiniquitLiquidacion(idCollaborate:string, data:any){
    return this.http.post(`${this.url}/admin/empresas/${localStorage.getItem('company')}/colaboradores/${idCollaborate}/finiquito_liquidacion/`, data)
    .pipe(
      map(
        nominas => nominas
      )
    )
  }

  //Carta de renuncia
  getResignationLetterByCollabrate(idCollaborate){
    return this.http.get(`${this.url}/admin/colaboradores/${idCollaborate}/generar_carta_renuncia`)
    .pipe(
      map(
        resignationLetter => resignationLetter
      )
    )
  }
}
