import {Injectable} from '@angular/core';
import {ImageModel} from '../../models/image/image.model';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.apiImage;
  }

  getHttOptions(): any {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const token = currentUser.token;
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + token
      })
    };
  }

  // Se conecta al S3 de AWS para recuperar la imagen
  public getImage(imageKey: string) {
    const options = {
      headers: this.getHttOptions(),
      responseType: 'blob' as 'json',
      body: {type: 'image/png'}
    };
    const url = `${this.url}/api/listobjects/pictures/${imageKey}`;
    console.log(url);
    return this.http.get(url, options)
      .pipe(
        map(image => {
          return image as any;
        })
      );
  }

  // El servicio obtiene las url de imagenes y PDFS
  getUrlDoduments(imageKey: string) {
    return this.http.get(`${this.url}/api/urlobjects/pictures/${imageKey}/`).
    pipe(
      map(
        urlImage => urlImage
      )
    );
  }

  getUrlProfile(imageKey: string){
    return this.http.get(`${this.url}/api/urlobjects/userprofile/${imageKey}/`).
    pipe(
      map(
        urlImageP => urlImageP
      )
    );
  }

  getImageProfile(imageKey: string) {
    const options = {
      headers: this.getHttOptions(),
      responseType: 'blob' as 'json',
      body: {type: 'image/png'}
    };
    const url = `${this.url}/api/listobjects/userprofile/${imageKey}`;
    console.log(url);
    return this.http.get(url, options)
      .pipe(
        map(image => {
          return image as any;
        })
      );
  }

  // Se suben archivos a AWS
  uploadFile(file: File, type: string): Observable<ImageModel> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${this.url}/api/uploadfile/`, formData)
      .pipe(
        map(image => {
          return image as ImageModel;
        })
      );
  }

  deleteFile(imageKey: string) {
    return this.http.delete(`${this.url}/api/deletefile/pictures/${imageKey}`)
      .pipe(
        map(image => {
          return image as ImageModel;
        })
      );
  }

  randomName(file: File): string {
    const max = 99999999999999999999;
    const min = 10000000000000000000;
    const ext = file.name.split('.').pop();
    const name = Math.floor(Math.random() * (max - min + 1)) + min;
    return name.toString() + '.' + ext;
  }
}
