import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InfonavitService {
  url:string;
  constructor(
    private http: HttpClient,
  ) {
    this.url = environment.api
  }

  createInfonavit(infonavit, idCollaborate){
    return this.http.post(`${this.url}/admin/colaboradores/${idCollaborate}/credito_infonavit/`, infonavit)
    .pipe(
      map(
        infonavit => infonavit
      )
    );
  }

  getInfonavit(idCollaborate){
    return this.http.get(`${this.url}/admin/colaboradores/${idCollaborate}/credito_infonavit/`)
    .pipe(
      map(
        infonavit => infonavit
      )
    );
  }

  upadateInfonavit(infonavit, idCollaborate, idInfonavit){
    return this.http.patch(`${this.url}/admin/colaboradores/${idCollaborate}/credito_infonavit/${idInfonavit}/`, infonavit)
    .pipe(
      map(
        infonavit => infonavit
      )
    );
  }
}
