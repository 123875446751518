import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EmaModel } from '../../models/calculations/ema.model';
import { EmaDeatailModel } from '../../models/calculations/ema-detail.model'

@Injectable({
  providedIn: 'root'
})
export class EmaService {
  url:string;
  year:number;
  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api
    let today = new Date();
    this.year = today.getFullYear();
  }

  getEmma(){
    /* cambiar año por la variable para que sea dinamico */
    return this.http.get<Array<EmaModel>>(`${this.url}/admin/empresas/${localStorage.getItem('company')}/year/2019/emas/`)
    .pipe(
      map(
        ema => ema
      )
    )
  }

  getEmmaById(idEma): Observable<EmaDeatailModel>{
    /* cambiar año por la variable para que sea dinamico */
    return this.http.get<EmaDeatailModel>(`${this.url}/admin/empresas/${localStorage.getItem('company')}/year/2019/emas/${idEma}/`)
    .pipe(
      map(
        ema => ema
      )
    )

  }
}
