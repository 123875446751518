import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChristmasBoxService {
  url:string;
  constructor(
    private http: HttpClient
  ) { 
    this.url = environment.api
  }

  //Aguinaldo
  
  getChritsmasBox(idNomina){
    console.log(idNomina)
    return this.http.get(`${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/aguinaldo/`)
    .pipe(
      map(
        christmasBox => christmasBox
      )
    )
  }

  getCollaboratorsChrismasbox(idNomina){
    return this.http.get(`${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/colaboradores_aguinaldo/`)
    .pipe(
      map(
        christmasBox => christmasBox
      )
    )
  }

  CalcularPreAguinaldo(idCollaborate){
    return this.http.get(`${this.url}/admin/empresas/${localStorage.getItem('company')}/colaboradores/${idCollaborate}/pre_aguinaldo`)
    .pipe(
      map(
        preChristmasBox => preChristmasBox
      )
    )
  }

  calculateChristmasBox(idNomina){
    return this.http.get(`${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/calcular_aguinaldo`)
    .pipe(
      map(
        christmasBox => christmasBox
      )
    )
  }

  closeChristmasBox(idNomina:number){
    return this.http.get(`${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/cerrar_aguinaldo/`)
    .pipe(
      map(
        closedChristmasBox => closedChristmasBox
      )
    )
  }
}
