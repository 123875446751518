import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable, Subject, pipe, empty} from 'rxjs';
import {CollaboratorRequestModel} from '../../models/collaborator/collaboratorRequest.model';
import {CollaboratorResponseModel} from '../../models/collaborator/collaboratorResponse.model'
import {DocumentsResponseModel} from '../../models/collaborator/documentsResponse.model';
import {RejectRequest, RequestModel} from '../../models/collaborator/request.model';
import { MoveCollaboratorModel } from '../../models/collaborator/moves-collaborator.model'

@Injectable({
  providedIn: 'root'
})
export class CollaboratorService {
  // Observables
  private filterCollaborateSubject = new Subject<string>();
  url: string;
  company: string;
  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api;
  }

  getCollaborator(filter?:any): Observable<CollaboratorResponseModel>{
    console.log(filter)
    let url: string = `${this.url}/admin/rh/agenda/?empresa=${localStorage.getItem('company')}`;
    if(filter == empty || !filter){
      url = url
    }
    if (filter.status) {
      url = `${url}&estatus=${filter.status}`
    }
    if(filter.branch){
      url = `${url}&sucursal=${filter.branch}`
    }
    if(filter.jobs){
      url = `${url}&puesto=${filter.jobs}`
    }
    if(filter.search){
      url = `${url}&search=${filter.search}`
    }
    console.log(url)
    return this.http.get(url)
    .pipe(
      map(
        collaborators => collaborators as CollaboratorResponseModel
      )
    );
  }

  addCollaborator(collaborate: CollaboratorRequestModel){
    return this.http.post(`${this.url}/admin/rh/empleados/`,collaborate)
    .pipe(
      map(
        collabotates => collabotates
      )
    );
  }

  getRequestCollaborator(): Observable<Array<RequestModel>> {
    return this.http.get<Array<RequestModel>>(`${this.url}/admin/rh/empleados/solicitudes/`)
      .pipe(
        map(
          collabotates => collabotates
        )
      );
  }

  rejectRequestCollaborator(request: RejectRequest, idRequest: number) {
    return this.http.put(`${this.url}/admin/rh/empleados/solicitudes/${idRequest}/`, request)
      .pipe(
        map(
          collabotates => collabotates
        )
      );
  }

  cancelationColaborate(data, id_collaborate){
    return this.http.post(`${this.url}/admin/colaboradores/${id_collaborate}/baja/`,data)
    .pipe(
      map(
        calcelation => calcelation
      )
    );
  }

  // Methods of Observable Company
  setParamOfFilterCollaborate(nameCollaborate: string) {
    this.filterCollaborateSubject.next(nameCollaborate);
  }

  getParamOfFilterCollaborate(): Observable<string> {
    return this.filterCollaborateSubject.asObservable();
  }

  getDocumentsValidate(id_collaborate:string): Observable<DocumentsResponseModel>{
    return this.http.get<DocumentsResponseModel>(`${this.url}/admin/rh/empleados/${id_collaborate}/validar_datos/`)
    .pipe(
      map(
        doc => doc
      )
    )
  }

  getMovesCollaborator(id_collaborate:string){
    return this.http.get<MoveCollaboratorModel>(`${this.url}/admin/colaboradores/${id_collaborate}/movimientos_afiliatorios/`)
    .pipe(
      map(
        moves => moves
      )
    )
  }

}
