import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {ListResponseModel, User} from '../../models/chat/listResponse.model';
import {HistoryModel} from '../../models/chat/history.model';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  urlChat: string;

  url: string;

  // Observables

  private chatHistorySubject = new Subject<User>();

  private messageSubject = new Subject<string>();

  private bottomChatSubject = new Subject<string>();

  constructor(
    private http: HttpClient
  ) {
    this.urlChat = environment.apiChat;
    this.url = environment.api;
  }

  getChatHistoryById(chatId: string): Observable<HistoryModel> {
    return this.http.get<HistoryModel>(`${this.urlChat}/chat/history/${chatId}/`)
      .pipe(
        map(
          history => history
        )
      );
  }

  getChatListWithTwoContacts(user: any, user2: any) {
    return this.http.get(`${this.urlChat}/chat/list/${user.id}/with/${user2.id}/`)
      .pipe(
        map(
          history => history
        )
      );
  }

  getChatListByUserId(userId: any): Observable<ListResponseModel> {
    return this.http.get<ListResponseModel>(`${this.urlChat}/chat/list/${userId}/`)
      .pipe(
        map(
          list => list
        )
      );
  }



  // Methods of Observable Chat

  setToGetHistoryChat(user: User) {
    this.chatHistorySubject.next(user);
  }

  getChatId() {
    return this.chatHistorySubject.asObservable();
  }

  setMessage(message: string) {
    this.messageSubject.next(message);
  }

  getMessage() {
    return this.messageSubject.asObservable();
  }

  setBottom(newMessage: string) {
    this.bottomChatSubject.next(newMessage);
  }

  getStatusBottom() {
    return this.bottomChatSubject.asObservable();
  }
}
