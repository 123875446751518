import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AnnexesModel} from '../../models/company/annexes.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnnexesService {

  private url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api;
  }

  getAnnexes(): Observable<Array<AnnexesModel>> {
    return this.http.get<Array<AnnexesModel>>(`${this.url}/admin/empresas/${localStorage.getItem('company')}/anexos/`)
      .pipe(
        map(
          res => res as Array<AnnexesModel>
        )
      );
  }

  addAnnexe(annexe: AnnexesModel) {
    return this.http.post(`${this.url}/admin/empresas/${localStorage.getItem('company')}/anexos/`, annexe)
      .pipe(
        map(
          res => res as AnnexesModel
        )
      );
  }

  updateAnnexe(annexe: AnnexesModel) {
    delete annexe.archivo;
    return this.http.patch(`${this.url}/admin/empresas/${localStorage.getItem('company')}/anexos/${annexe.id}/`, annexe)
      .pipe(
        map(
          res => res as AnnexesModel
        )
      );
  }
}
