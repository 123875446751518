import { Component, OnInit } from '@angular/core';
import { FirebaseService } from "./service/index";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'fiwi';
  message;

  constructor(private fireBaseService: FirebaseService){

  }

  ngOnInit(){
   /*  const userId = 'user001';
    this.fireBaseService.requestPermission(userId)
    this.fireBaseService.receiveMessage()
    this.message = this.fireBaseService.currentMessage */
  }
}
