import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {LegalRequestModel} from '../../models/company/legalRequest.model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class LegalService {
  url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api;
  }

  registerLegalPerson(person: LegalRequestModel): Observable<any> {
    return this.http.post(`${this.url}/admin/empresas/${localStorage.getItem('company')}/representante_legal/`, person)
      .pipe(
        map(
          per => per
        )
      );
  }

  getRegisterLegal(): Observable<LegalRequestModel> {
    return this.http.get<LegalRequestModel>(`${this.url}/admin/empresas/${localStorage.getItem('company')}/representante_legal/`)
      .pipe(
        map(
          repLegal => repLegal
        )
      );
  }

  updateRegisterLegal(person: LegalRequestModel) {
    return this.http.put(`${this.url}/admin/empresas/${localStorage.getItem('company')}/representante_legal/${person.id}/`, person)
      .pipe(
        map(
          repLegal => repLegal
        )
      );
  }

  addCertificate(files, company): Observable<any>{
    return this.http.post(`${this.url}/admin/empresas/${company}/certificados/`, files)
    .pipe(
      map(cer => cer)
    )
  }

  getCertificate(company:string){
    return this.http.get(`${this.url}/admin/empresas/${company}/certificados/`)
    .pipe(
      map(cer => cer)
    )
  }
}
