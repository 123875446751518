import { Injectable } from '@angular/core';
import {UserResponse} from '../../models/login/login.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  protected user: UserResponse;

  constructor() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
  }

  get name(): string {
    return this.user.name;
  }

  get id(): number {
    return this.user.id;
  }

}
