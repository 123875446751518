import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {BranchModel} from '../../models/company/branch.model';

@Injectable()
export class BranchService {

  url: string;

  // Observables
  private tableBranchesSubject = new Subject<BranchModel>();

  private branchSubject = new Subject<BranchModel>();

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api;
  }

  addBranch(branch: BranchModel) {
    return this.http.post(`${this.url}/admin/empresas/${localStorage.getItem('company')}/sucursales/`, branch)
      .pipe(
        map(
          branchR => branchR
        )
      );
  }

  getBranches(): Observable<Array<BranchModel>> {
    return this.http.get(`${this.url}/admin/empresas/${localStorage.getItem('company')}/sucursales/`)
      .pipe(
        map(
          branchR => branchR as Array<BranchModel>
        )
      );
  }


  updateBranch(branch: BranchModel) {
    return this.http.put(`${this.url}/admin/empresas/${localStorage.getItem('company')}/sucursales/${branch.id}/`, branch)
      .pipe(
        map(
          branchR => branchR
        )
      );
  }

  // Methods of Observable Branch

  setUpdateTableBranches(branch: BranchModel) {
    this.tableBranchesSubject.next(branch);
  }

  getStatusTableBranch() {
    return this.tableBranchesSubject.asObservable();
  }

  setToeditBranch(branch: BranchModel) {
    this.branchSubject.next(branch);
  }

  getToEditBranch() {
    return this.branchSubject.asObservable();
  }
}
