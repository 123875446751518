import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable, Subject, observable} from 'rxjs';
import {EmployerResponseModel} from '../../models/company/employerResponse.model';
import {EmployerRequestModel} from '../../models/company/employerRequest.model';
import { Certificate } from 'crypto';

@Injectable()
export class EmployerRegisterService {
  // Observables
  private filterCompanySubject = new Subject<string>();
  url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api;
  }

  addEmployer(employer: EmployerRequestModel, company:string): Observable<any>{
    return this.http.post(`${this.url}/admin/empresas/${company}/registro_patronal/`, employer)
    .pipe(
      map(emp => emp)
    )
  }

  updateEmployer(employer: EmployerRequestModel, company: string, id: number){
    return this.http.put(`${this.url}/admin/empresas/${company}/registro_patronal/${id}/`, employer)
    .pipe(
      map(emp => emp)
    )
  }

  getEmployers(company: string): Observable<Array<EmployerResponseModel>>{
    return this.http.get(`${this.url}/admin/empresas/${company}/registro_patronal/`)
      .pipe(
        map(
          emp => emp as Array<EmployerResponseModel>
        )
      );
  }

  // Methods of Observable Company
  setParamOfFilterEmployer(nameEmployer: string) {
    this.filterCompanySubject.next(nameEmployer);
  }

  getParamOfFilterEmployer(): Observable<string> {
    return this.filterCompanySubject.asObservable();
  }

}
