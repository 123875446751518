import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { TypeIncapacityModel } from '../../models/collaborator/catalogs/type-incapacity.model';
import { CategoryIncapacityModel } from '../../models/collaborator/catalogs/category-incapacity.model';
import { SubcategoryIncapacityModel } from '../../models/collaborator/catalogs/subcategory-incapacity.model';
import { ClasificationImssModel } from '../../models/collaborator/catalogs/clasification-imss-model';

@Injectable({
  providedIn: 'root'
})
export class CatalogsCollaboratorService {
  url: string;
  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api;
  }
  
  /* catalogos de incapacidad */
  getTypeIncapacity(){
    return this.http.get<Array<TypeIncapacityModel>>(`${this.url}/catalogos/tipo_incapacidad/`)
      .pipe(
        map(typeIncapacity => typeIncapacity)
      );
  }

  getCategoryIncapacity(){
    return this.http.get<Array<CategoryIncapacityModel>>(`${this.url}/catalogos/categorias_incapacidad/`)
      .pipe(
        map(categoryIncapacity => categoryIncapacity)
      );
  }

  getSubcategoryIncapacity(){
    return this.http.get<Array<SubcategoryIncapacityModel>>(`${this.url}/catalogos/subcategorias_incapacidad/`)
      .pipe(
        map(subcategoryIncapacity => subcategoryIncapacity)
      );
  }

  getImssClasification(){
    return this.http.get<Array<ClasificationImssModel>>(`${this.url}/catalogos/clasificacion_incapacidad_imss/`)
      .pipe(
        map(imssClasification => imssClasification)
      );
  }

  /* catalogos de infonavit */
  getTypeMoveInfonavit(){
    return this.http.get(`${this.url}/catalogos/tipo_movimiento_infonavit/`)
      .pipe(
        map(moveInfonavit => moveInfonavit)
      );
  }
}
