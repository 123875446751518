import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {TaxRegimeModel} from '../../models/catalogs/tax-regime.model';
import {map} from 'rxjs/operators';
import {StatesModel} from '../../models/catalogs/states.model';
import {DelegationsModel} from '../../models/catalogs/delegations.model';
import {SubdelegationsImssModel} from '../../models/catalogs/subdelegations-imss.model';
import {GeographicAreaModel} from '../../models/catalogs/geographic-area.model';
import {ClasertModel} from '../../models/catalogs/clase-rt.model';
import {FractionrtModel} from '../../models/catalogs/fraction-rt.model';
import {GlobalCatalogModel} from '../../models/collaborator/globalCatalog.model';
import {LeadersModel} from '../../models/collaborator/leaders.model';
import {RiskpremiumModel} from '../../models/catalogs/riskpremium.model';
import {AntiquityModel} from '../../models/catalogs/antiquity.model';
import {Profesion, ProfesionModel} from '../../models/catalogs/profesions.model';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {

  url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api;
  }

  getTaxRegime(): Observable<Array<TaxRegimeModel>> {
    return this.http.get<Array<TaxRegimeModel>>(`${this.url}/catalogos/regimen_fiscal/`)
      .pipe(
        map(tax => tax)
      );
  }

  getStates() {
    return this.http.get<Array<StatesModel>>(`${this.url}/catalogos/estados/`)
      .pipe(
        map(states => states)
      );
  }

  getSubdelegationImss(id_delegation): Observable<Array<SubdelegationsImssModel>>{
    return this.http.get<Array<SubdelegationsImssModel>>(`${this.url}/catalogos/delegaciones_imss/${id_delegation}/subdelegaciones`)
      .pipe(
        map(
          subdelegation => subdelegation
        )
      )
  }

  getDelagtions(): Observable<Array<DelegationsModel>>{
    return this.http.get<Array<DelegationsModel>>(`${this.url}/catalogos/delegaciones_imss`)
      .pipe(
        map(
          delegation => delegation
        )
      )
  }

  getGeographicArea(): Observable<Array<GeographicAreaModel>>{
    return this.http.get<Array<GeographicAreaModel>>(`${this.url}/catalogos/areas_geograficas_imss`)
    .pipe(
      map(
        geographic_area => geographic_area
      )
    )
  }

  getClassRT(): Observable<Array<ClasertModel>>{
    return this.http.get<Array<ClasertModel>>(`${this.url}/catalogos/clases_riesgos_trabajo/`)
    .pipe(
      map(clase_rt => clase_rt)
    )
  }

  getFractionRT(): Observable<Array<FractionrtModel>>{
    return this.http.get<Array<FractionrtModel>>(`${this.url}/catalogos/fraccion_riesgos_trabajo/`)
    .pipe(
      map(clase_rt => clase_rt)
    )
  }

  getTypeCollaborateImms(): Observable<Array<GlobalCatalogModel>>{
    return this.http.get<Array<GlobalCatalogModel>>(`${this.url}/catalogos/tipo_trabajador_imss/`)
    .pipe(
      map(typeImss_c => typeImss_c)
    )
  }

  getTypePension(): Observable<Array<GlobalCatalogModel>>{
    return this.http.get<Array<GlobalCatalogModel>>(`${this.url}/catalogos/pension/`)
    .pipe(
      map(pension => pension)
    )
  }

  getRegimeEngagement(): Observable<Array<GlobalCatalogModel>>{
    return this.http.get<Array<GlobalCatalogModel>>(`${this.url}/catalogos/regimen_contratacion/`)
    .pipe(
      map(Engagement => Engagement)
    )
  }

  getleaders(): Observable<Array<LeadersModel>>{
    return this.http.get<Array<LeadersModel>>(`${this.url}/catalogos/proveedor_despensa/`)
    .pipe(
      map(leader => leader)
    )
  }

  getTypeWorkingDay(): Observable<Array<GlobalCatalogModel>>{
    return this.http.get<Array<GlobalCatalogModel>>(`${this.url}/catalogos/jornada/`)
    .pipe(
      map(WD => WD)
    )
  }

  getTypeTax(): Observable<Array<GlobalCatalogModel>>{
    return this.http.get<Array<GlobalCatalogModel>>(`${this.url}/catalogos/tipo_impuesto/`)
    .pipe(
      map(tax => tax)
    )
  }

  getPayMetehod(): Observable<Array<GlobalCatalogModel>>{
    return this.http.get<Array<GlobalCatalogModel>>(`${this.url}/catalogos/forma_pago/`)
    .pipe(
      map(pay_m => pay_m)
    )
  }


  getTypeSalary(){
    return this.http.get<Array<GlobalCatalogModel>>(`${this.url}/catalogos/salario/`)
    .pipe(
      map(pay_m => pay_m)
    )
  }

  getRiskPremium(): Observable<Array<RiskpremiumModel>> {
    return this.http.get<Array<RiskpremiumModel>>(`${this.url}/catalogos/prima_riesgo/`)
      .pipe(
        map(risk => risk)
      );
  }

  getAntiquity(): Observable<Array<AntiquityModel>> {
    return this.http.get<Array<AntiquityModel>>(`${this.url}/catalogos/constantes_antiguedad/`)
      .pipe(
        map(risk => risk)
      );
  }

  getProfesions(): Observable<ProfesionModel> {
    return this.http.get<ProfesionModel>(`${this.url}/catalogos/constantes_salario_minimo/`)
      .pipe(
        map(risk => risk)
      );
  }
}
