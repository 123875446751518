import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {DepartmentModel} from '../../models/company/department.model';

@Injectable()
export class DepartmentService {

  url: string;

  // Observables
  private tableDepartmentSubject = new Subject<DepartmentModel>();

  private departmentSubject = new Subject<DepartmentModel>();

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api;
  }

  addDepartment(departament: DepartmentModel): Observable<DepartmentModel> {
    return this.http.post(`${this.url}/admin/empresas/${localStorage.getItem('company')}/departamentos/`, departament)
      .pipe(
        map(
          departaments => departaments as DepartmentModel
        )
      );
  }

  updateDepartment(departament: DepartmentModel): Observable<DepartmentModel> {
    return this.http.post(`${this.url}/admin/empresas/${localStorage.getItem('company')}/departamentos/${departament.id}/`, departament)
      .pipe(
        map(
          departaments => departaments as DepartmentModel
        )
      );
  }

  deleteDepartment() {
    
  }

  getDepartments() {
    return this.http.get(`${this.url}/admin/empresas/${localStorage.getItem('company')}/departamentos/`)
      .pipe(
        map(
          branchR => branchR as Array<DepartmentModel>
        )
      );
  }

  // Methods of Observable Department

  setUpdateTableDepartment(department: DepartmentModel) {
    this.tableDepartmentSubject.next(department);
  }

  getStatusTableDepartment() {
    return this.tableDepartmentSubject.asObservable();
  }

  setToeditDepartament(departament: DepartmentModel) {
    this.departmentSubject.next(departament);
  }

  getToEditDepartament() {
    return this.departmentSubject.asObservable();
  }
}
