import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import { IncapacityModel } from '../../models/collaborator/incapacity.model';
@Injectable({
  providedIn: 'root'
})
export class IncapacityService {
  url:string;
  constructor(
    private http: HttpClient,
  ) { 
    this.url = environment.api
  }

  getIncapacity(idCollaborate){
    return this.http.get<Array<IncapacityModel>>(`${this.url}/admin/colaboradores/${idCollaborate}/incapacidades/`)
      .pipe(
        map(incapacity => incapacity)
      );
  }

  addIncapacity(incapacity, idCollaborate){
    return this.http.post(`${this.url}/admin/colaboradores/${idCollaborate}/incapacidades/`,incapacity)
    .pipe(
      map(incapacity => incapacity)
    );
  }
}
