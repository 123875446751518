import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {Observable, Subject, pipe, from} from 'rxjs';
import { NominasResponseModel } from '../../models/calculations/nominas.model';
import { CalcularNominaModel } from '../../models/calculations/calcularNomina.model';


@Injectable({
  providedIn: 'root'
})
export class CalculationService {
  url:string;
  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api
  }


  
  sendIncidence(idNomina: number, idPeriodo: number, idColaborador: number, data: any) {
    return this.http.post(`${this.url}/admin/nominas/${idNomina}/periodos/${idPeriodo}/colaboradores/${idColaborador}/incidencias/`, data)
      .pipe(
        map(
          incidence => incidence
        )
      )
  }

  // Nominas
  getNominas(company, type_payroll?, date_start?, date_end?): Observable<Array<NominasResponseModel>>{
    console.log(type_payroll, date_start, date_end)
    let url_request;
    if(type_payroll && date_start == '' && date_end == ''){
      url_request =`${this.url}/admin/empresas/${company}/nominas/${type_payroll}`
    }else if(type_payroll && date_start != '' && date_end == ''){
      url_request =`${this.url}/admin/empresas/${company}/nominas/${type_payroll}?fecha_inicial=${date_start}&fecha_final=${date_end}`
    }else if(type_payroll && date_start == '' && date_end != ''){
      url_request =`${this.url}/admin/empresas/${company}/nominas/${type_payroll}?fecha_inicial=${date_start}&fecha_final=${date_end}`
    }
    else if(type_payroll && date_start != '' && date_end != ''){
      url_request =`${this.url}/admin/empresas/${company}/nominas/${type_payroll}?fecha_inicial=${date_start}&fecha_final=${date_end}`
    }
    console.log(url_request)
   return this.http.get<Array<NominasResponseModel>>(url_request)
   .pipe(
     map(
       nominas => nominas
     )
   )
  }

  getCollaboratePayRoll(idPeriodo, idNomina, branch?, departament?, search?){
    let URL;
    if(branch && departament && search){
      URL = `${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/periodos/${idPeriodo}/colaboradores/?search=${search}&departamento=${departament}&branch=${branch}`;
    }else if(branch && !departament && search){
      URL = `${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/periodos/${idPeriodo}/colaboradores/?search=${search}&branch=${branch}`;
    }else if(branch && !departament && !search){
      URL = `${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/periodos/${idPeriodo}/colaboradores/?branch=${branch}`;
    }else if(branch && departament && !search){
      URL = `${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/periodos/${idPeriodo}/colaboradores/?branch=${branch}&departamento=${departament}`;
    }else if(!branch && !departament && search){
      URL = `${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/periodos/${idPeriodo}/colaboradores/?search=${search}`;
    }else if(!branch && departament && search){
      URL = `${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/periodos/${idPeriodo}/colaboradores/?search=${search}&departamento=${departament}`;
    }else if(branch && !departament && search){
      URL = `${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/periodos/${idPeriodo}/colaboradores/?search=${search}&branch=${branch}`;
    }else if(!branch && departament && !search){
      URL = `${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/periodos/${idPeriodo}/colaboradores/?departamento=${departament}`;
    }else if(!branch && departament && search){
      URL = `${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/periodos/${idPeriodo}/colaboradores/?departamento=${departament}&search=${search}`;
    }else if(branch && departament && !search){
      URL = `${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/periodos/${idPeriodo}/colaboradores/?departamento=${departament}&branch=${branch}`;
    }else if(!branch && !departament &&  !search){
      URL = `${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idNomina}/periodos/${idPeriodo}/colaboradores/`;
    }
    console.log('url', URL)
    return this.http.get(URL)
    .pipe(
      map(
        collaborate => collaborate
      )
    )
  }

  calculatePreNomina(id_nomina, id_periodo, id_collaborate){
    return this.http.get(`${this.url}/admin/nominas/${id_nomina}/periodos/${id_periodo}/colaboradores/${id_collaborate}/pre_nomina/`)
    .pipe(
      map(
        collaborate => collaborate
      )
    )
  }

  generarNomina(id_nomina, id_periodo): Observable<CalcularNominaModel>{
    return this.http.get<CalcularNominaModel>(`${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${id_nomina}/periodos/${id_periodo}/generar_nomina/`)
    .pipe(
      map(
        nominas => nominas
      )
    )
  }

  closePayRoll(idPayRoll:number, idPeriod:number){
    return this.http.get(`${this.url}/admin/empresas/${localStorage.getItem('company')}/nominas/${idPayRoll}/periodos/${idPeriod}/cerrar_nomina/`)
    .pipe(
      map(
        closedPayRoll => closedPayRoll
      )
    )
  }

  


}
