import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable, Subject, pipe} from 'rxjs';
import { ValidationDataModel } from  '../../models/collaborator/validationData.model';
import { stateValidationModel } from  '../../models/collaborator/stateValidation.model';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  url:string;
  constructor(private http: HttpClient){
    this.url = environment.api 
  }

  validationData(id_collaborate, data:any){
    return this.http.put(`${this.url}/admin/rh/empleados/${id_collaborate}/validar_datos/`,data)
    .pipe(
      map(
        fixed => fixed
      )
    )
  }

  validationDocument(id_collaborate, id_document, body){
    return this.http.put(`${this.url}/admin/rh/empleados/${id_collaborate}/documentos/${id_document}/`, body)
    .pipe(
      map(
        fixed => fixed
      )
    )
  }

  getStateValidation(id_collaborate):Observable<stateValidationModel>{
    return this.http.get<stateValidationModel>(`${this.url}/admin/rh/empleados/${id_collaborate}/validacion/`)
    .pipe(
      map(
        state => state
      )
    )
  }

  addDataComplements(id_collaborate, data){
    return this.http.post(`${this.url}/admin/rh/empleados/${id_collaborate}/datos_complementarios/`,data)
    .pipe(
      map(
        collabotates => collabotates
      )
    );
  }

  assignConttract(id_collaborate: string, data){
    return this.http.post(`${this.url}/admin/rh/empleados/${id_collaborate}/contratacion/`,data)
    .pipe(
      map(
        collabotates => collabotates
      )
    );
  }

}
