import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import {TypeSweet} from '../../utils/TypeSweet';

@Injectable({
  providedIn: 'root'
})
export class SweetalertService {

  constructor() { }

  basicSweet(title: string, message: string, icon: TypeSweet) {
    Swal.fire({
      title: `${title}`,
      icon: `${icon}`,
      text: `${message}`,
      showConfirmButton: false,
      heightAuto: false,
      allowOutsideClick: false,
      timer: 1000
    });
  }

  askSweetAlert(title: string, text: string, icon: TypeSweet){
    return Swal.fire({
      title: `${title}`,
      text: `${text}`,
      icon: `${icon}`,
      showCancelButton: true,
      confirmButtonColor: '#1ABC9C',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar'
    })
  }
}
