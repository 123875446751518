import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { BenenitDescountModel } from '../../models/calculations/benefit.model';
import {Observable, Subject, pipe, from} from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BenefitsDiscountsService {
  url:string;
  constructor(
    private http: HttpClient
  ) {
       this.url = environment.api
  }

  // Beneficios y descuentos
  getBenefits(): Observable<Array<BenenitDescountModel>>{
    return this.http.get<Array<BenenitDescountModel>>(`${this.url}/catalogos/tipo_beneficio_descuento/`)
    .pipe(
      map(
        benefits => benefits
      )
    )
  }

  applyBenefits(body, id_nomina, id_periodo, id_collaborate){
    return this.http.post(`${this.url}/admin/nominas/${id_nomina}/periodos/${id_periodo}/colaboradores/${id_collaborate}/beneficios_descuentos/`, body)
    .pipe(
      map(
        benefitsApply => benefitsApply
      )
    )
  }
}
