import {NgModule} from '@angular/core';
import {
  MatInputModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatCardModule,
  MatPaginatorModule,
  MatSortModule,
  MatBadgeModule,
  MatSlideToggleModule,
  MatButtonToggleModule,
  MatSelectModule,
  MatMenuModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatGridListModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatRadioModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatTableModule,
  MatSliderModule,
  MatToolbarModule,
  MatDividerModule,
  MatTabsModule,
  MatStepperModule,
  MatChipsModule,
  MatButtonModule,
  MatTreeModule,
  MatBottomSheetModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatSnackBarModule, MatRippleModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MaterialFileInputModule } from 'ngx-material-file-input';

const MODULE_MATERIAL = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatAutocompleteModule,
  MatInputModule,
  MatCheckboxModule,
  MatInputModule,
  MatRadioModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatSelectModule,
  MatDividerModule,
  MatExpansionModule,
  MatTableModule,
  MatSliderModule,
  MatToolbarModule,
  MatCardModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatTabsModule,
  MatSidenavModule,
  MatGridListModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatPaginatorModule,
  MatBadgeModule,
  MatSortModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatButtonToggleModule,
  MatGridListModule,
  MatListModule,
  MatStepperModule,
  MatTreeModule,
  MatButtonModule,
  MatChipsModule,
  MatProgressBarModule,
  MatBottomSheetModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatRippleModule,
  TextFieldModule,
  MaterialFileInputModule
];
@NgModule({
  imports: [
    MODULE_MATERIAL
  ],
  exports : [
   MODULE_MATERIAL
  ],
})
export class MaterialModule { }
