import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { map } from 'rxjs/operators';
import { Observable, Subject} from 'rxjs';
import { BenefitsModel } from '../../models/company/benefits.model';

@Injectable({
  providedIn: 'root'
})
export class BenefitsService {
  url: string;
  // Observables
  private tableBenefitsSubject = new Subject<BenefitsModel>();
  constructor(private http: HttpClient) {
    this.url = environment.api;
  }

  addBenefit(benefit: BenefitsModel, company){
    return this.http.post(`${this.url}/admin/empresas/${company}/prestaciones/`, benefit)
    .pipe(
      map(
        benefit => benefit
      )
    )
  }

  getStatusTableBenefits() {
    return this.tableBenefitsSubject.asObservable();
  }

  getBenefits(): Observable<Array<BenefitsModel>>{
    let company = localStorage.getItem('company')
    return this.http.get(`${this.url}/admin/empresas/${company}/prestaciones/`)
    .pipe(
      map(
        benefit => benefit as Array<BenefitsModel>
      )
    )
  }

  setUpdateTableBenefits(benefit: BenefitsModel) {
    this.tableBenefitsSubject.next(benefit);
  }
}
