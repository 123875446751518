import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {CompanyResponseModel} from '../../models/company/companyResponse.model';
import {CompanyRequestModel} from '../../models/company/companyRequest.model';

@Injectable()
export class CompanyService {

  url: string;

  // Observables
  private filterCompanySubject = new Subject<string>();

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api;
  }

  getCompanies(name?: string): Observable<Array<CompanyResponseModel>> {
    let url: string;
    if(url){
      url = `${this.url}/admin/empresas/?search=${name}`
    } else{
      url = `${this.url}/admin/empresas/`
    }
    return this.http.get<Array<CompanyResponseModel>>(url)
      .pipe(
        map(
          companies => companies
        )
      );
  }

  createCompany(company: CompanyRequestModel) {
    return this.http.post<CompanyRequestModel>(`${this.url}/admin/empresas/`, company)
      .pipe(
        map(
          companyResponse => companyResponse
        )
      );
  }

  // Methods of Observable Company
  setParamOfFilterCompany(nameCompany: string) {
    this.filterCompanySubject.next(nameCompany);
  }

  getParamOfFilterCompany(): Observable<string> {
    return this.filterCompanySubject.asObservable();
  }
 }
