import { Injectable } from '@angular/core';
import { FixedconceptsModel } from '../../models/company/fixed-concepts.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FixedConceptsService {
  url:string;
  constructor(private http: HttpClient ) 
  { this.url = environment.api }

  applyconcept(concept: FixedconceptsModel, company){
    return this.http.post(`${this.url}/admin/empresas/${company}/conceptos_fijos_empresa/`, concept)
    .pipe(
      map(
        fixed => fixed
      )
    )
  }

  getConcepts(company){
    return this.http.get(`${this.url}/admin/empresas/${company}/conceptos_fijos_empresa/`)
    .pipe(
      map(
        fixed => fixed
      )
    )
  }

}
