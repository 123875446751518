import { Injectable } from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  ws: WebSocket;

  socketIsOpen = 1;

  constructor() { }

  createObservableSocket(idChat: string, userIdRHorAdmin: number, userId: number): Observable<any> {
    this.ws = new WebSocket(`${environment.chat}/ws/chat/${idChat}_${userIdRHorAdmin}_${userId}/`);

    return new Observable(
      observer => {
        this.ws.onmessage = (event) => observer.next(JSON.parse(event.data));

        this.ws.onerror = (event) => observer.error(event);

        this.ws.onclose = (event) => observer.complete();

        return () => this.ws.close(1000, 'The user disconnected');
      }
    );
  }

  closeSocket() {
    if (this.ws) {
      // console.log('cerrando Socket');
      this.ws.close(1000, 'The user disconnected');
    }
  }

  sendMessage(message: any): string {
    if (this.ws.readyState === this.socketIsOpen) {
      this.ws.send(JSON.stringify(message));
    } else {
      return 'error';
    }
  }


}
