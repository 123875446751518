import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ContractModel} from '../../models/company/contract.model';

@Injectable()
export class ContractService {

  url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.api;
  }

  addContract(contract) {
    return this.http.post(`${this.url}/admin/empresas/${localStorage.getItem('company')}/contratos/`, contract)
      .pipe(
        map(
          contractR => contractR
        )
      );
  }

  getContracts(): Observable<Array<ContractModel>> {
    return this.http.get<Array<ContractModel>>(`${this.url}/admin/empresas/${localStorage.getItem('company')}/contratos/`)
      .pipe(
        map(
          contracts => contracts as Array<ContractModel>
        )
      );
  }

  deleteContract(idContract: number): Observable<any> {
    return this.http.delete(`${this.url}/admin/empresas/${localStorage.getItem('company')}/contratos/${idContract}/`)
      .pipe(
        map(
          contract => contract
        )
      );
  }

  updateContract(contract) {
    return this.http.patch(`${this.url}/admin/empresas/${localStorage.getItem('company')}/contratos/${contract.id}/`, contract)
      .pipe(
        map(
          contractR => contractR
        )
      );
  }
}
